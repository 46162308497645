import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Accordion,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./NewCheckOut.css";
import { connect } from "react-redux";
import { fetchSingleDeliveryAddressStart } from "../store/actions/DeliveryAction";
import { fetchCartListStart } from "../store/actions/CartsAction";
import SomethingWrong from "../Helper/SomethingWrong";
import Skeleton from "react-loading-skeleton";
import { fetchUserWalletDetailsStart } from "../store/actions/WalletAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { saveOrdersCartStart, ordersCheckoutStart, orderStatusStart } from "../store/actions/OrdersAction";
import AddMoneyModal from "./AddMoneyModal";
import {
  useAccount,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import CheckoutWalletButton from "./CheckoutWalletButton";
// import QRCode from "react-qr-code";
// import upiqr from "upiqr";
import * as Yup from "yup";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { get } from "react-global-configuration";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

const CheckoutPayment = (props) => {
  const account = useAccount();
  const navigate = useNavigate();
  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);
  const [checkout, setCheckout] = useState(true);
  const [addMoney, setAddMoney] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [vpa, setVpa] = useState("AMIGOMI@icici")
  const [redirectUrl, setRedirectUrl] = useState("");
  const [paymentStarted, setPaymentStarted] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("addessUniqueId")) {
      props.dispatch(fetchSingleDeliveryAddressStart({
        delivery_address_unique_id: localStorage.getItem("addessUniqueId")
      }));
      props.dispatch(fetchCartListStart());
      props.dispatch(fetchUserWalletDetailsStart());
    } else {
      navigate("/checkout-address");
    }
  }, []);

  // useEffect(() => {
  //   if (!skipRender && !props.cartList.loading && Object.keys(props.cartList.data).length > 0 && props.userWalletDetails.data.user_wallet &&
  //     !props.userWalletDetails.loading && Object.keys(props.userWalletDetails.data).length > 0 && props.cartList.data.user_cart_amount) {
  //     if (props.userWalletDetails.data.user_wallet.remaining >= props.cartList.data.user_cart_amount.cart_final_amount) {
  //       setCheckout(true);
  //     } else {
  //       setCheckout(false);
  //     }
  //   }
  // }, [props.userWalletDetails, props.cartList]);

  useEffect(() => {
    if (!skipRender &&
      (!props.singleDeliveryAddress.loading && (props.singleDeliveryAddress.error !== false || !props.singleDeliveryAddress.data.delivery_address)) ||
      (!props.cartList.loading && (props.cartList.error !== false || !props.cartList.data.carts || !props.cartList.data.user_cart_amount ||
        !props.cartList.data.availability || !props.cartList.data.availability.is_available)) ||
      (!props.userWalletDetails.loading && (props.userWalletDetails.error !== false || !props.userWalletDetails.data.user_wallet))) {
      setShowContent(false);
    }
  }, [props.singleDeliveryAddress, props.cartList, props.userWalletDetails]);

  useEffect(() => {
    if (!skipRender && !props.orderSaveCart.loading && Object.keys(props.orderSaveCart.data).length > 0) {
      localStorage.removeItem("addessUniqueId");
      navigate("/order-placed");
    }
    setSkipRender(false);
  }, [props.orderSaveCart]);

  const changeAddress = e => {
    e.preventDefault();
    localStorage.removeItem("addessUniqueId");
    navigate("/checkout-address");
  }

  const checkoutCart = (values) => {

    props.dispatch(saveOrdersCartStart({
      delivery_address_id: props.singleDeliveryAddress.data.delivery_address.delivery_address_id,
      // payment_mode: "UPI",
      payment_mode: "COD",
      ...values
    }));
  }

  const closeAddMoneyModal = () => {
    setAddMoney(false);
  }

  // const onCheckout = e => {
  //   e.preventDefault();
  //   const amount = "10";
  //   const stableTokenAmount = amount.toString();
  //   const web3 = window.web3;
  //   let stable_token_amount = window.web3.utils.toWei(stableTokenAmount, "Ether");
  //   // let stable_token_amount = web3.utils.toWei(stableTokenAmount);

  //   let USDVToken = new web3.eth.Contract(
  //     Token.abi,
  //     "0xcD7509b76281223f5B7d3aD5d47F8D7Aa5C2B9bf"
  //   );
  //   USDVToken.methods
  //     .approve(
  //       // configuration.get("configData.seller_contract_address"),
  //       "0xcBD25127B97D1F170b5efFa36A7894FCbf43314D",
  //       stable_token_amount
  //     )
  //     .send({ from: walletAccount.address })
  //     .on("receipt", (receipt) => {
  //       console.log(receipt);
  //     })
  //     .on("error", (error) => {
  //       // setButtonContent("");
  //       console.log("Error", error);
  //       if (error.message == undefined) {
  //         props.dispatch(getErrorNotificationMessage("Unexpected error occuried, Please try again..."));
  //       } else {
  //         props.dispatch(getErrorNotificationMessage(error.message));
  //       }
  //       // Send error notification.
  //     });
  // }

  const onFormSubmit = (paymentMode, data = {}) => {
    props.dispatch(ordersCheckoutStart({
      payment_mode: paymentMode,
      delivery_address_id: props.singleDeliveryAddress.data.delivery_address.delivery_address_id,
      ...data,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.ordersCheckout.loading && Object.keys(props.ordersCheckout.data).length > 0) {
      localStorage.removeItem("addessUniqueId");
      navigate("/order-placed");
    }
    setSkipRender(false);
  }, [props.ordersCheckout]);

  // useEffect(() => {
  //   if (!skipRender && !props.cartList.loading && Object.keys(props.cartList.data).length > 0) {
  //     upiqr({
  //       payeeVPA: vpa,
  //       payeeName: "test M",
  //       amount: props.cartList.data.user_cart_amount.cart_final_amount.toFixed(2)
  //     })
  //       .then(({ qr }) => {
  //         setQrCode(qr)
  //       })
  //       .catch(console.error)
  //   }
  //   setSkipRender(false);
  // }, [props.cartList]);

  const checkOutSchema = Yup.object().shape({
    payment_id: Yup.string().required(t("required")),
  });

  useEffect(() => {
    if (!props.upiIntentCheckout.loading && Object.keys(props.upiIntentCheckout.data).length > 0) {
      setRedirectUrl(props.upiIntentCheckout.data?.data?.transaction?.upi_url)
    }
  }, [props.upiIntentCheckout])

  useEffect(() => {
    const getChargersData = () => {
      props.dispatch(orderStatusStart({
        order_id: props.upiIntentCheckout.data?.data?.transaction?.order_id,
      }));
    }
    getChargersData()
    const interval = setInterval(() => {
      getChargersData()
    },5*1000);
    return () => clearInterval(interval);
  },[]);

  useEffect(() => {
    if (!props.orderStatus.loading && Object.keys(props.orderStatus.data).length > 0 && Object.keys(props.orderStatus.data.data).length > 0 && props.orderStatus.data.data?.transaction?.status != "Initiated") {
      navigate("/order-placed");
    }
  }, [props.orderStatus])

  console.log("orderStatus", props.orderStatus)

  return (
    <>
      <div className="newcheckout-sec">
        {showContent
          ? <Container>
            <Row>
              <Col xl={8} md={12} className="resp-mrg-btm-md">
                <div className="newcheckout-header-sec">
                  <ul className="list-unstyled new-checkout-progress-bar">
                    <li className="step1">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          1
                        </div>
                        <h6>{t("address")}</h6>
                      </div>
                    </li>
                    <li className="step2">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          2
                        </div>
                        <h6>{t("order_summary")}</h6>
                      </div>
                    </li>
                    <li className="step3">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          3
                        </div>
                        <h6>{t("payment")}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="newcheckout-body-wrapper">

                  <div className="step-3">
                    <div className="product-delivery-address-box">
                      {props.singleDeliveryAddress.loading
                        ? <Skeleton height={200} />
                        : props.singleDeliveryAddress.data.delivery_address
                          ? <div className="saved-address-box">
                            <div className="address-details">
                              <h3>{t("deliver_to")}</h3>
                              <h4>{props.singleDeliveryAddress.data.delivery_address.name}</h4>
                              <p className="address-block">
                                {props.singleDeliveryAddress.data.delivery_address.address}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.city}{" "}
                                {props.singleDeliveryAddress.data.delivery_address.state}{" "}
                                {props.singleDeliveryAddress.data.delivery_address.pincode}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.landmark}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.contact_number}
                              </p>
                            </div>
                            <div className="address-btn">
                              <Button
                                className="address-button"
                                onClick={changeAddress}
                              >
                                {t("change")}
                              </Button>
                            </div>
                          </div>
                          : null
                      }
                    </div>
                    <div className="product-order-summary-card">
                      <h3>{t("order_summary")} - <span>{
                        props.cartList.loading
                          ? "..."
                          : props.cartList.data.carts ?
                            props.cartList.data.carts.filter(cart => cart.product.stock_status === 1).length
                            : ""
                      } {t("products")}</span></h3>
                      <div className="address-btn">
                        <Link to="/cart" className="address-button">
                          {t("change")}
                        </Link>
                      </div>
                    </div>
                    <div className="company-bages">
                      <h5>Billing Address From :</h5>
                      <p>
                        <strong>  AMIGOMI INFOSOLUTION PRIVATE LIMITED. </strong><br />
                        #9/2, Amarakrupa 3rd Main 9th C, Sadashivanagar, Bangalore North, Bangalore - 560080, karnataka
                      </p>
                    </div>
                    {/* <div className="payment-radio-box">
                      {props.userWalletDetails.loading ?
                        <Skeleton height={100} />
                        : checkout
                          ? <div className="payment-radio-btn label-bg dropdown-wallet">
                            <input
                              name="plan"
                              className="radio"
                              type="radio"
                              checked
                              id="payment-2"
                            />
                            <label
                              name="plan"
                              className="radio"
                              type="radio"
                              checked
                              for="payment-2"
                            >
                              <p>
                                <Image
                                  src={
                                    window.location.origin + "/images/wallet.svg"
                                  }
                                  type="image/png"
                                />
                                <span>{t("wallet")}</span>
                              </p>
                              <p>{props.userWalletDetails.data.user_wallet.remaining_formatted}</p>
                            </label>
                          </div>
                          : <div className="payment-radio-btn label-bg dropdown-wallet mt-3">
                            <label
                              name="plan"
                              className="payment-add-money-wallet"
                            >
                              <p>
                                <Image
                                  src={
                                    window.location.origin + "/images/wallet.svg"
                                  }
                                  type="image/png"
                                />
                                <span>{t("wallet")}</span>
                              </p>
                              <p>{props.userWalletDetails.data.user_wallet.remaining_formatted}</p>
                              <Button
                                className="address-button"
                                onClick={() => setAddMoney(true)}
                              >
                                {t("add_money")}
                              </Button>
                            </label>
                          </div>
                      }
                      {props.cartList.loading ?
                        ""
                        : props.cartList.data.user_cart_amount &&
                          props.cartList.data.availability &&
                          props.cartList.data.availability.is_available
                          ? <>
                             {account.address ?
                              !props.singleDeliveryAddress.loading ?
                                <CheckoutWalletButton
                                  cartFinalAmount={props.cartList.data.user_cart_amount.cart_final_amount}
                                  onFormSubmit={onFormSubmit}
                                />
                                : null
                              :
                              <>
                                <div className="payment-radio-btn label-bg dropdown-wallet mt-3">
                                  <label
                                    name="plan"
                                    className="payment-add-money-wallet"
                                  >
                                    <p>
                                      <Image
                                        src={
                                          window.location.origin + "/images/wallet.svg"
                                        }
                                        type="image/png"
                                      />
                                      <span>Wallet</span>
                                    </p>
                                    <p>Not Connected</p>
                                    <ConnectKitButton.Custom>
                                      {({ isConnected, isConnecting, show, hide, address, ensName }) => {
                                        return (
                                          <Button
                                            className="address-button"
                                            onClick={() => show()}
                                          >
                                            Connect
                                          </Button>
                                        );
                                      }}
                                    </ConnectKitButton.Custom>
                                  </label>
                                </div>
                              </>
                            }
                            <div className="payment-radio-btn label-bg dropdown-wallet mt-3">
                              <label
                                name="plan"
                                className="payment-add-money-wallet"
                              >
                                <p>
                                  <Image
                                      src={
                                        window.location.origin + "/images/wallet.svg"
                                      }
                                      type="image/png"
                                    />
                                  <span>Checkout</span>
                                </p>
                                <p>Not Connected</p>
                                <Button
                                  className="address-button"
                                  onClick={() => onFormSubmit("ADMIN")}
                                  disabled={props.ordersCheckout.buttonDisable}
                                >
                                  {props.ordersCheckout.loadingButtonContent ? props.ordersCheckout.loadingButtonContent : "Pay"}
                                </Button>
                              </label>
                            </div> 
                          </>
                          : null
                      }
                    </div> */}
                    {/* <Link to="#" className="add-new-address-link">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" /></svg>
                    ADD CARD
                  </Link> */}
                    {/* Vithya - BD Team asked only COD Payment */}
                    {redirectUrl && (
                      <Formik
                        initialValues={{
                          payment_id: "",
                        }}
                        validationSchema={checkOutSchema}
                        onSubmit={(values) => checkoutCart(values)}
                      >
                        {({ touched, errors }) => (
                          <FORM>
                            <>
                              <div className="upi-payment-box">
                                <div className="upi-payment-left-sec">
                                  {/* <Image
                                className="upi-qr-code"
                                  src={
                                  window.location.origin + "/images/upi.png"}
                                /> */}
                                  {paymentStarted ? (
                                    <div className="payment-spinner">
                                    </div>
                                  ) : (
                                    <a
                                      className="address-button"
                                      onClick={() => setPaymentStarted(true)}
                                      href={redirectUrl}
                                    >
                                      Pay Now
                                    </a>
                                  )}

                                  <div className="or-sec">

                                  </div>
                                  {/* <div className="upi-id-copy-sec"> 
                                  <Form.Label>UPI ID:</Form.Label>
                                  <CopyToClipboard
                                    text={vpa}
                                    onCopy={() => getSuccessNotificationMessage("Upi ID Copied")}
                                  >
                                    <InputGroup className="mb-0">
                                      <Form.Control
                                        disabled
                                        value={vpa}
                                      />
                                      <InputGroup.Text id="basic-addon2">
                                        <Button className="copy-btn" type="button">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-copy"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg>
                                        </Button>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </CopyToClipboard>
                                </div>
                                */}
                                </div>

                                {/* <div className="upi-payment-right-sec">

                                <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Transaction ID:</Form.Label>
                                  <Field type="text" name="payment_id" className="form-control" placeholder="Enter the Transaction ID" />
                                </Form.Group>
                                <ErrorMessage
                                  component={"div"}
                                  name="payment_id"
                                  className="text-danger text-right"
                                />
                              </div> */}
                              </div>
                              {/* <div className="product-confirm-continue-box">
                              <h4>Confirm And Pay</h4>
                              <div className="address-btn">
                                <Button
                                  disabled={!checkout || props.orderSaveCart.buttonDisable}
                                  className="address-button"
                                  type="submit"
                                >
                                  {props.orderSaveCart.loadingButtonContent ?
                                    props.orderSaveCart.loadingButtonContent
                                    : t("continue")
                                  }
                                </Button>
                              </div>
                            </div> */}
                            </>
                          </FORM>
                        )}
                      </Formik>
                    )}
                    {/* Vithya - BD Team asked only COD Payment */}

                    {/* <Formik
                      initialValues={{
                        payment_id: "",
                      }}
                      // validationSchema={checkOutSchema}
                      onSubmit={(values) => checkoutCart(values)}
                    >
                      {({ touched, errors }) => (
                        <FORM>                            
                            <div className="product-confirm-continue-box">
                              <h4>{t("cod_payment")}</h4>
                              <div className="address-btn">
                                <Button
                                  disabled={!checkout || props.orderSaveCart.buttonDisable}
                                  className="address-button"
                                  type="submit"
                                >
                                  {props.orderSaveCart.loadingButtonContent ?
                                    props.orderSaveCart.loadingButtonContent
                                    : t("cod_order_confirm")
                                  }
                                </Button>
                              </div>
                            </div>
                        </FORM>
                      )}
                    </Formik> */}

                  </div>

                </div>
              </Col>
              {/* <Col xl={4} md={12}>
                <div className="checkout-box">
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("price_details")}</h4>
                    </div>
                    {props.cartList.loading
                      ? <Skeleton height={200} />
                      : <div className="cart-body-sec">
                        <div className="cart-summary-info">
                          <div className="cart-summary-header">
                            <p>
                              {t("cart_total")} <span>{props.cartList.data.user_cart_amount.cart_total_amount}</span>
                            </p>
                            <p>
                              {t("discount")} <span>-{props.cartList.data.user_cart_amount.cart_discount_amount}</span>
                            </p>
                          </div>
                          <div className="cart-summary-total-amount">
                            <h5>
                              {t("order_total")} <span>{props.cartList.data.user_cart_amount.cart_checkout_amount}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
          : <div className="text-center">
            <SomethingWrong />
            <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
          </div>
        }
      </div>
      {addMoney ?
        <AddMoneyModal
          addMoney={addMoney}
          closeAddMoneyModal={closeAddMoneyModal}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.carts.cartList,
  singleDeliveryAddress: state.delivery.singleDeliveryAddress,
  userWalletDetails: state.wallet.userWalletDetails,
  orderSaveCart: state.orders.orderSaveCart,
  ordersCheckout: state.orders.ordersCheckout,
  upiIntentCheckout: state.orders.upiIntentCheckout,
  orderStatus: state.orders.orderStatus,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CheckoutPayment));
