export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const FETCH_STATIC_PAGE_START = "FETCH_STATIC_PAGE_START";
export const FETCH_STATIC_PAGE_SUCCESS = "FETCH_STATIC_PAGE_SUCCESS";
export const FETCH_STATIC_PAGE_FAILURE = "FETCH_STATIC_PAGE_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REGENERATE_EMAIL_VERIFICATION_CODE_START = "REGENERATE_EMAIL_VERIFICATION_CODE_START";
export const REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS = "REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS";
export const REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE = "REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE";

export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const TWO_STEP_AUTH_LOGIN_START = "TWO_STEP_AUTH_LOGIN_START";
export const TWO_STEP_AUTH_LOGIN_SUCCESS = "TWO_STEP_AUTH_LOGIN_SUCCESS";
export const TWO_STEP_AUTH_LOGIN_FAILURE = "TWO_STEP_AUTH_LOGIN_FAILURE";

export const TWO_STEP_AUTH_RESEND_CODE_START = "TWO_STEP_AUTH_RESEND_CODE_START";
export const TWO_STEP_AUTH_RESEND_CODE_SUCCESS = "TWO_STEP_AUTH_RESEND_CODE_SUCCESS";
export const TWO_STEP_AUTH_RESEND_CODE_FAILURE = "TWO_STEP_AUTH_RESEND_CODE_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const TWO_STEP_AUTH_UPDATE_START = "TWO_STEP_AUTH_UPDATE_START";
export const TWO_STEP_AUTH_UPDATE_SUCCESS = "TWO_STEP_AUTH_UPDATE_SUCCESS";
export const TWO_STEP_AUTH_UPDATE_FAILURE = "TWO_STEP_AUTH_UPDATE_FAILURE";

export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const ADD_USER_CARD_START = "ADD_USER_CARD_START";
export const ADD_USER_CARD_SUCCESS = "ADD_USER_CARD_SUCCESS";
export const ADD_USER_CARD_FAILURE = "ADD_USER_CARD_FAILURE";

export const FETCH_USER_CARD_LIST_START = "FETCH_USER_CARD_LIST_START";
export const FETCH_USER_CARD_LIST_SUCCESS = "FETCH_USER_CARD_LIST_SUCCESS";
export const FETCH_USER_CARD_LIST_FAILURE = "FETCH_USER_CARD_LIST_FAILURE";

export const DELETE_USER_CARD_START = "DELETE_USER_CARD_START";
export const DELETE_USER_CARD_SUCCESS = "DELETE_USER_CARD_SUCCESS";
export const DELETE_USER_CARD_FAILURE = "DELETE_USER_CARD_FAILURE";

export const SET_DEFAULT_USER_CARD_START = "SET_DEFAULT_USER_CARD_START";
export const SET_DEFAULT_USER_CARD_SUCCESS = "SET_DEFAULT_USER_CARD_SUCCESS";
export const SET_DEFAULT_USER_CARD_FAILURE = "SET_DEFAULT_USER_CARD_FAILURE";

export const ADD_USER_BILLING_ACCOUNTS_START = "ADD_USER_BILLING_ACCOUNTS_START";
export const ADD_USER_BILLING_ACCOUNTS_SUCCESS = "ADD_USER_BILLING_ACCOUNTS_SUCCESS";
export const ADD_USER_BILLING_ACCOUNTS_FAILURE = "ADD_USER_BILLING_ACCOUNTS_FAILURE";

export const FETCH_USER_BILLING_ACCOUNTS_START = "FETCH_USER_BILLING_ACCOUNTS_START";
export const FETCH_USER_BILLING_ACCOUNTS_SUCCESS = "FETCH_USER_BILLING_ACCOUNTS_SUCCESS";
export const FETCH_USER_BILLING_ACCOUNTS_FAILURE = "FETCH_USER_BILLING_ACCOUNTS_FAILURE";

export const DELETE_USER_BILLING_ACCOUNTS_START = "DELETE_USER_BILLING_ACCOUNTS_START";
export const DELETE_USER_BILLING_ACCOUNTS_SUCCESS = "DELETE_USER_BILLING_ACCOUNTS_SUCCESS";
export const DELETE_USER_BILLING_ACCOUNTS_FAILURE = "DELETE_USER_BILLING_ACCOUNTS_FAILURE";

export const SET_DEFAULT_USER_BILLING_ACCOUNTS_START = "SET_DEFAULT_USER_BILLING_ACCOUNTS_START";
export const SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS = "SET_DEFAULT_USER_BILLING_ACCOUNTS_SUCCESS";
export const SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE = "SET_DEFAULT_USER_BILLING_ACCOUNTS_FAILURE";

export const FETCH_USER_WALLET_DETAILS_START = "FETCH_USER_WALLET_DETAILS_START";
export const FETCH_USER_WALLET_DETAILS_SUCCESS = "FETCH_USER_WALLET_DETAILS_SUCCESS";
export const FETCH_USER_WALLET_DETAILS_FAILURE = "FETCH_USER_WALLET_DETAILS_FAILURE";
export const FETCH_MORE_USER_WALLET_DETAILS_START = "FETCH_MORE_USER_WALLET_DETAILS_START";

export const WALLET_PAYMENT_BY_STRIPE_START = "WALLET_PAYMENT_BY_STRIPE_START";
export const WALLET_PAYMENT_BY_STRIPE_SUCCESS = "WALLET_PAYMENT_BY_STRIPE_SUCCESS";
export const WALLET_PAYMENT_BY_STRIPE_FAILURE = "WALLET_PAYMENT_BY_STRIPE_FAILURE";

export const WITHDRAWAL_REQUEST_START = "WITHDRAWAL_REQUEST_START";
export const WITHDRAWAL_REQUEST_SUCCESS = "WITHDRAWAL_REQUEST_SUCCESS";
export const WITHDRAWAL_REQUEST_FAILURE = "WITHDRAWAL_REQUEST_FAILURE";

export const CANCEL_WITHDRAWAL_REQUEST_START = "CANCEL_WITHDRAWAL_REQUEST_START";
export const CANCEL_WITHDRAWAL_REQUEST_SUCCESS = "CANCEL_WITHDRAWAL_REQUEST_SUCCESS";
export const CANCEL_WITHDRAWAL_REQUEST_FAILURE = "CANCEL_WITHDRAWAL_REQUEST_FAILURE";

export const FETCH_WITHDRAWL_REQUESTS_START = "FETCH_WITHDRAWL_REQUESTS_START";
export const FETCH_WITHDRAWL_REQUESTS_SUCCESS = "FETCH_WITHDRAWL_REQUESTS_SUCCESS";
export const FORCE_WITHDRAWL_REQUESTS_SUCCESS = "FORCE_WITHDRAWL_REQUESTS_SUCCESS";
export const FETCH_WITHDRAWL_REQUESTS_FAILURE = "FETCH_WITHDRAWL_REQUESTS_FAILURE";
export const FETCH_MORE_WITHDRAWAL_REQUESTS_START = "FETCH_MORE_WITHDRAWAL_REQUESTS_START";

export const VIEW_SINGLE_WITHDRAWAL_REQUEST_START = "VIEW_SINGLE_WITHDRAWAL_REQUEST_START";
export const VIEW_SINGLE_WITHDRAWAL_REQUEST_SUCCESS = "VIEW_SINGLE_WITHDRAWAL_REQUEST_SUCCESS";
export const VIEW_SINGLE_WITHDRAWAL_REQUEST_FAILURE = "VIEW_SINGLE_WITHDRAWAL_REQUEST_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const FETCH_USER_WALLET_ADDRESS_START = "FETCH_USER_WALLET_ADDRESS_START";
export const FETCH_USER_WALLET_ADDRESS_SUCCESS = "FETCH_USER_WALLET_ADDRESS_SUCCESS";
export const FETCH_USER_WALLET_ADDRESS_FAILURE = "FETCH_USER_WALLET_ADDRESS_FAILURE";

export const FETCH_SALES_REPORT_START = "FETCH_SALES_REPORT_START";
export const FETCH_SALES_REPORT_SUCCESS = "FETCH_SALES_REPORT_SUCCESS";
export const FETCH_SALES_REPORT_FAILURE = "FETCH_SALES_REPORT_FAILURE";

export const CONTACT_SUBMIT_START = "CONTACT_SUBMIT_START";
export const CONTACT_SUBMIT_SUCCESS = "CONTACT_SUBMIT_SUCCESS";
export const CONTACT_SUBMIT_FAILURE = "CONTACT_SUBMIT_FAILURE";

//carts
export const SAVE_CART_DETAILS_START = "SAVE_CART_DETAILS_START";
export const SAVE_CART_DETAILS_SUCCESS = "SAVE_CART_DETAILS_SUCCESS";
export const SAVE_CART_DETAILS_FAILURE = "SAVE_CART_DETAILS_FAILURE";

export const FETCH_CART_LIST_START = "FETCH_CART_LIST_START";
export const FETCH_CART_LIST_SUCCESS = "FETCH_CART_LIST_SUCCESS";
export const FETCH_CART_LIST_FAILURE = "FETCH_CART_LIST_FAILURE";

export const DELETE_CART_DETAILS_START = "DELETE_CART_DETAILS_START";
export const DELETE_CART_DETAILS_SUCCESS = "DELETE_CART_DETAILS_SUCCESS";
export const DELETE_CART_DETAILS_FAILURE = "DELETE_CART_DETAILS_FAILURE";

export const GUEST_CART_LIST = "GUEST_CART_LIST";

export const FETCH_GUEST_CART_LIST_START = "FETCH_GUEST_CART_LIST_START";
export const UPDATE_GUEST_CART_LIST_START = "UPDATE_GUEST_CART_LIST_START";
export const FETCH_GUEST_CART_LIST_SUCCESS = "FETCH_GUEST_CART_LIST_SUCCESS";
export const FETCH_GUEST_CART_LIST_FAILURE = "FETCH_GUEST_CART_LIST_FAILURE";

//order
export const SAVE_ORDERS_DIRECT_START = "SAVE_ORDERS_DIRECT_START";
export const SAVE_ORDERS_DIRECT_SUCCESS = "SAVE_ORDERS_DIRECT_SUCCESS";
export const SAVE_ORDERS_DIRECT_FAILURE = "SAVE_ORDERS_DIRECT_FAILURE";

export const SAVE_ORDERS_CART_START = "SAVE_ORDERS_CART_START";
export const SAVE_ORDERS_CART_SUCCESS = "SAVE_ORDERS_CART_SUCCESS";
export const SAVE_ORDERS_CART_FAILURE = "SAVE_ORDERS_CART_FAILURE";

export const ORDER_STATUS_START = "ORDER_STATUS_START";
export const ORDER_STATUS_SUCCESS = "ORDER_STATUS_SUCCESS";
export const ORDER_STATUS_FAILURE = "ORDER_STATUS_FAILURE";

export const FETCH_ORDERS_LIST_START = "FETCH_ORDERS_LIST_START";
export const FETCH_MORE_ORDERS_LIST_START = "FETCH_MORE_ORDERS_LIST_START";
export const FETCH_ORDERS_LIST_SUCCESS = "FETCH_ORDERS_LIST_SUCCESS";
export const FETCH_ORDERS_LIST_FAILURE = "FETCH_ORDERS_LIST_FAILURE";

export const FETCH_ORDERS_DETAILS_START = "FETCH_ORDERS_DETAILS_START";
export const FETCH_ORDERS_DETAILS_SUCCESS = "FETCH_ORDERS_DETAILS_SUCCESS";
export const FETCH_ORDERS_DETAILS_FAILURE = "FETCH_ORDERS_DETAILS_FAILURE";

export const CANCEL_ORDERS_START = "CANCEL_ORDERS_START";
export const CANCEL_ORDERS_SUCCESS = "CANCEL_ORDERS_SUCCESS";
export const CANCEL_ORDERS_FAILURE = "CANCEL_ORDERS_FAILURE";

export const INVOICE_ORDERS_START = "INVOICE_ORDERS_START";
export const INVOICE_ORDERS_SUCCESS = "INVOICE_ORDERS_SUCCESS";
export const INVOICE_ORDERS_FAILURE = "INVOICE_ORDERS_FAILURE";

export const TRANSACTION_LIST_START = "TRANSACTION_LIST_START";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";
export const TRANSACTION_LIST_FAILURE = "TRANSACTION_LIST_FAILURE";
export const MORE_TRANSACTION_LIST_START = "MORE_TRANSACTION_LIST_START";

export const ORDERS_SEND_CANCEL_REQUEST_START = "ORDERS_SEND_CANCEL_REQUEST_START";
export const ORDERS_SEND_CANCEL_REQUEST_SUCCESS = "ORDERS_SEND_CANCEL_REQUEST_SUCCESS";
export const ORDERS_SEND_CANCEL_REQUEST_FAILURE = "ORDERS_SEND_CANCEL_REQUEST_FAILURE";

export const ORDERS_SEND_RETURN_REQUEST_START = "ORDERS_SEND_RETURN_REQUEST_START";
export const ORDERS_SEND_RETURN_REQUEST_SUCCESS = "ORDERS_SEND_RETURN_REQUEST_SUCCESS";
export const ORDERS_SEND_RETURN_REQUEST_FAILURE = "ORDERS_SEND_RETURN_REQUEST_FAILURE";

export const ORDERS_CHECKOUT_START = "ORDERS_CHECKOUT_START";
export const ORDERS_CHECKOUT_SUCCESS = "ORDERS_CHECKOUT_SUCCESS";
export const ORDERS_CHECKOUT_FAILURE = "ORDERS_CHECKOUT_FAILURE";

export const UPI_INITENT_CHECKOUT_START = "UPI_INITENT_CHECKOUT_START";
export const UPI_INITENT_CHECKOUT_SUCCESS = "UPI_INITENT_CHECKOUT_SUCCESS";
export const UPI_INITENT_CHECKOUT_FAILURE = "UPI_INITENT_CHECKOUT_FAILURE";

// delivery
export const SAVE_DELIVERY_ADDRESSES_START = "SAVE_DELIVERY_ADDRESSES_START";
export const SAVE_DELIVERY_ADDRESSES_SUCCESS = "SAVE_DELIVERY_ADDRESSES_SUCCESS";
export const SAVE_DELIVERY_ADDRESSES_FAILURE = "SAVE_DELIVERY_ADDRESSES_FAILURE";

export const FETCH_DELIVERY_ADDRESSES_LIST_START = "FETCH_DELIVERY_ADDRESSES_LIST_START";
export const FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS = "FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS";
export const FETCH_DELIVERY_ADDRESSES_LIST_FAILURE = "FETCH_DELIVERY_ADDRESSES_LIST_FAILURE";

export const MAKE_DEFAULT_DELIVERY_ADDRESSES_START = "MAKE_DEFAULT_DELIVERY_ADDRESSES_START";
export const MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS = "MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS";
export const MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE = "MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE";

export const DELETE_DELIVERY_ADDRESSES_START = "DELETE_DELIVERY_ADDRESSES_START";
export const DELETE_DELIVERY_ADDRESSES_SUCCESS = "DELETE_DELIVERY_ADDRESSES_SUCCESS";
export const DELETE_DELIVERY_ADDRESSES_FAILURE = "DELETE_DELIVERY_ADDRESSES_FAILURE";

export const FETCH_SINGLE_DELIVERY_ADDRESS_START = "FETCH_SINGLE_DELIVERY_ADDRESS_START";
export const FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS = "FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS";
export const FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE = "FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE";

export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_STATES_START = "GET_STATES_START";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

// homepage
export const FETCH_BANNERS_LIST_START = "FETCH_BANNERS_LIST_START";
export const FETCH_BANNERS_LIST_SUCCESS = "FETCH_BANNERS_LIST_SUCCESS";
export const FETCH_BANNERS_LIST_FAILURE = "FETCH_BANNERS_LIST_FAILURE";

export const FETCH_CATEGORIES_LIST_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_LIST_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_LIST_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_TOP_PRODUCTS_LIST_START = "FETCH_TOP_PRODUCTS_LIST_START";
export const FETCH_TOP_PRODUCTS_LIST_SUCCESS = "FETCH_TOP_PRODUCTS_LIST_SUCCESS";
export const FETCH_TOP_PRODUCTS_LIST_FAILURE = "FETCH_TOP_PRODUCTS_LIST_FAILURE";

// My Review & Ratings
export const SAVE_REVIEWS_START = "SAVE_REVIEWS_START";
export const SAVE_REVIEWS_SUCCESS = "SAVE_REVIEWS_SUCCESS";
export const SAVE_REVIEWS_FAILURE = "SAVE_REVIEWS_FAILURE";

export const SAVE_REVIEWS_FILES_START = "SAVE_REVIEWS_FILES_START";
export const SAVE_REVIEWS_FILES_SUCCESS = "SAVE_REVIEWS_FILES_SUCCESS";
export const SAVE_REVIEWS_FILES_FAILURE = "SAVE_REVIEWS_FILES_FAILURE";

export const DELETE_REVIEWS_FILES_START = "DELETE_REVIEWS_FILES_START";
export const DELETE_REVIEWS_FILES_SUCCESS = "DELETE_REVIEWS_FILES_SUCCESS";
export const DELETE_REVIEWS_FILES_FAILURE = "DELETE_REVIEWS_FILES_FAILURE";

export const FETCH_REVIEWS_LIST_START = "FETCH_REVIEWS_LIST_START";
export const FETCH_REVIEWS_LIST_SUCCESS = "FETCH_REVIEWS_LIST_SUCCESS";
export const FETCH_REVIEWS_LIST_FAILURE = "FETCH_REVIEWS_LIST_FAILURE";

export const DELETE_REVIEWS_START = "DELETE_REVIEWS_START";
export const DELETE_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS";
export const DELETE_REVIEWS_FAILURE = "DELETE_REVIEWS_FAILURE";

export const CUSTOMER_REVIEWS_LIST_START = "CUSTOMER_REVIEWS_LIST_START";
export const CUSTOMER_REVIEWS_LIST_SUCCESS = "CUSTOMER_REVIEWS_LIST_SUCCESS";
export const CUSTOMER_REVIEWS_LIST_FAILURE = "CUSTOMER_REVIEWS_LIST_FAILURE";

//Product Action
export const FETCH_PRODUCT_LIST_START = "FETCH_PRODUCT_LIST_START";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FORCE_PRODUCT_LIST_SUCCESS = "FORCE_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";
export const FETCH_MORE_PRODUCT_LIST_START = "FETCH_MORE_PRODUCT_LIST_START";

export const FETCH_SINGLE_PRODUCT_START = "FETCH_SINGLE_PRODUCT_START";
export const FETCH_SINGLE_PRODUCT_SUCCESS = "FETCH_SINGLE_PRODUCT_SUCCESS";
export const FETCH_SINGLE_PRODUCT_FAILURE = "FETCH_SINGLE_PRODUCT_FAILURE";

export const FETCH_PRODUCT_REVIEW_LIST_START = "FETCH_PRODUCT_REVIEW_LIST_START";
export const FETCH_MORE_PRODUCT_REVIEW_LIST_START = "FETCH_MORE_PRODUCT_REVIEW_LIST_START";
export const FETCH_PRODUCT_REVIEW_LIST_SUCCESS = "FETCH_PRODUCT_REVIEW_LIST_SUCCESS";
export const FORCE_FETCH_PRODUCT_REVIEW_LIST_SUCCESS = "FORCE_FETCH_PRODUCT_REVIEW_LIST_SUCCESS";
export const FETCH_PRODUCT_REVIEW_LIST_FAILURE = "FETCH_PRODUCT_REVIEW_LIST_FAILURE";

export const FETCH_PRODUCT_SUGGETION_START = "FETCH_PRODUCT_SUGGETION_START";
export const FETCH_PRODUCT_SUGGETION_SUCCESS = "FETCH_PRODUCT_SUGGETION_SUCCESS";
export const FETCH_PRODUCT_SUGGETION_FAILURE = "FETCH_PRODUCT_SUGGETION_FAILURE";

// My Wishlists

export const FETCH_PRODUCT_WISHLIST_LIST_START = "FETCH_PRODUCT_WISHLIST_LIST_START";
export const FETCH_MORE_PRODUCT_WISHLIST_LIST_START = "FETCH_MORE_PRODUCT_WISHLIST_LIST_START";
export const FETCH_PRODUCT_WISHLIST_LIST_SUCCESS = "FETCH_PRODUCT_WISHLIST_LIST_SUCCESS";
export const FORCE_PRODUCT_WISHLIST_LIST_SUCCESS = "FORCE_PRODUCT_WISHLIST_LIST_SUCCESS";
export const FETCH_PRODUCT_WISHLIST_LIST_FAILURE = "FETCH_PRODUCT_WISHLIST_LIST_FAILURE";

export const SAVE_WISHLIST_START = "SAVE_WISHLIST_START";
export const SAVE_WISHLIST_SUCCESS = "SAVE_WISHLIST_SUCCESS";
export const SAVE_WISHLIST_FAILURE = "SAVE_WISHLIST_FAILURE";

// FAQ Lists

export const FETCH_FAQS_LIST_START = "FETCH_FAQS_LIST_START";;
export const FETCH_FAQS_LIST_SUCCESS = "FETCH_FAQS_LIST_SUCCESS";
export const FETCH_FAQS_LIST_FAILURE = "FETCH_FAQS_LIST_FAILURE";

